.App {
  text-align: center;
}

.App-logo {
  height: 20vmin;
  pointer-events: none;
  border-radius: 10px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.App-content {
  background-color: #282c34;
  display: flex;
  flex-direction: column;
  color: white;
}